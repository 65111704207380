<div style="width: 100%; border-radius: 20px; padding: 28px; background-color: white; color: rgba(0, 0, 0, 0.85)">
  <div class="row">
    <div class="col-sm-12 col-md-2" style="padding-left: 48px; padding-bottom: 20px; overflow: hidden">
      <img [src]="review.avatar_path ? review.avatar_path : '/assets/img/ReviewsPlaceholderImage.jpg'" alt="avatar of review author" width="60" height="60" style="border-radius: 50%">
    </div>
    <div class="col-sm-12 col-md-10">
      <div style="margin-bottom: 20px; overflow: hidden">
        <h4 style="float: left; margin-right: 12px">A review created by {{ review.author }}</h4>
        <div style="background-color: black; border-radius: 12px; color: white; padding: 0 8px; float: left; font-size: 20px">✭ {{ review.rating }}</div>
      </div>
      <div style="margin-bottom: 12px; color: gray">Written by {{ review.author }} on {{ formatCreatedAtStr(review.created_at) }}</div>
      <div class="max-row-3" style="margin-bottom: 12px; color: rgba(0, 0, 0, 0.85)">{{ review.content }}</div>
      <div><a [href]="review.url" target="_blank" style="color: rgb(30, 32, 100)"><u>Read the rest</u></a></div>
    </div>
  </div>
</div>
